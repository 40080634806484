import router from '@/router';
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class RedeemListViewModel {
  constructor() {
    this.tabData = {
      value: 'ALL',
    };

    this.data = {
      boardData: {
        title: '전체 목록',
        drawDataList: [
          {
            title: 'id',
            width: '60px',
            value: 'id',
            isSorting: true,
            isAlignLeft: true,
          },
          {
            title: 'categoryType',
            value: 'categoryType',
          },
          {
            title: 'mapId',
            value: 'mapId',
          },
          {
            title: '코드',
            value: 'code',
            class: {
              name: 'td_point',
            },
            isAlignLeft: true,
          },
          {
            title: 'status',
            value: 'status',
          },
          {
            title: 'createdAt',
            value: 'createdAt',
            filter: { name: 'convertDateFormat', value: 'YYYY-MM-DD HH:mm' },
          },
        ],
        option: {
          isTotal: true,
          isSize: true,
        },
      },
      ALL: {
        dataList: [],
        searchParams: {
          page: 0,
          pageSize: 10,
          direction: 'DESC',
        },
        paginationData: {
          totalCount: 0,
          totalPage: 0,
        },
      },
    };
    this.checkboxOnlyOne = false;
    this.selectedDataList = [];
  }
  init(query) {
    const { pageSize, page } = query;
    if (pageSize) {
      this.data.ALL.searchParams.pageSize = Number(pageSize);
    }
    if (page) {
      this.data.ALL.searchParams.page = Number(page);
    }
    this.getData();
  }
  onSearch() {
    this.getData();
  }
  onClickAdd() {
    router.push({
      name: 'SERVICE_REDEEM_REGISTER',
      query: {
        pageSize: this.data.ALL.searchParams.pageSize,
        page: this.data.ALL.searchParams.page,
      },
    });
  }
  getData() {
    const query = makeQueryStringByObject(this.data.ALL.searchParams);
    const path = `${apiPath.REDEEM_LIST}${query}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        this.data.ALL.dataList = resultData.list;
        this.data.ALL.paginationData.totalCount = resultData.total;
        this.data.ALL.paginationData.totalPage = resultData.totalPage;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}
