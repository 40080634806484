<template>
  <PageWithLayout>
    <Board
      :boardData="viewModel.data.boardData"
      :searchParams.sync="viewModel.data[viewModel.tabData.value].searchParams"
      :dataList="viewModel.data[viewModel.tabData.value].dataList"
      :paginationData="viewModel.data[viewModel.tabData.value].paginationData"
      @onSearch="viewModel.onSearch()"
    >
      <!-- @onClickRow="(rowId, rowData) => viewModel.onClickRow(rowId, rowData)" -->
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="리딤코드 생성"
          @onClickBtn="viewModel.onClickAdd()"
        >
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template>
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import RedeemListViewModel from '@/views/service/redeem/viewModel/RedeemListViewModel';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';

export default {
  name: 'RedeemList',
  components: {
    PageWithLayout,
    Board,
    Button,
    IconSvg,
  },
  data() {
    return {
      viewModel: new RedeemListViewModel(),
    };
  },
  beforeMount() {
    this.viewModel.init(this.$route.query);
  },
};
</script>
